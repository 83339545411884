<template>
  <div class="app-input">
    <label v-if="label" :for="id">
      {{ label }} <span v-if="required" class="required">*</span>
      <span v-if="notRequired" class="not-required">- необязательно</span>
    </label>

    <div
      class="input"
      :class="{
        input_error: error,
      }"
    >
      <slot></slot>
      <input
        :type="type"
        :id="id"
        v-model="formattedValue"
        @input="updateInputValue"
        :disabled="disabled"
        :placeholder="placeholder"
      />
    </div>

    <span class="error" v-if="error && !hideError">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AppInput",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },
  },

  computed: {
    formattedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  methods: {
    updateInputValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },

    clearInput() {
      this.$emit("update:modelValue", "");
    },
  },
};
</script>

<style scoped lang="scss">
.app-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;
  transition: ease-out 0.05s;

  > label {
    color: $text-primary;
    @include caption-regular();
  }

  &:hover {
    > .input {
      border-color: $stroke-primary;
    }
  }

  > .input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-space;
    border: 1px solid $stroke-secondary;
    border-radius: 8px;
    height: 56px;
    padding: 0 ($base-space * 4);

    @media (max-width: 1023px) {
      height: 48px;
    }

    > input {
      width: 100%;
      height: 100%;
      @include s-text-regular();
      color: $text-primary;
      padding: 0;
      background: none;
      border: none;
      outline: none;

      &::placeholder {
        color: $text-sixth;
      }
    }

    &:focus-within {
      border-color: $accent;
    }

    &_error {
      border-color: $second-accent;
    }
  }
}
</style>
