<template>
  <AppModal @close="$emit('close-modal')">
    <div class="request-modal">
      <h3>Заявка на консультацию</h3>
      <p>Оставьте заявку и мы свяжемся с вами в течение 15 минут</p>

      <form @submit.prevent="sendRequest" class="form">
        <fieldset>
          <AppInput
            id="name"
            v-model="name"
            label="Ваше имя"
            required
            :error="v$.name?.$errors[0]?.$message"
          />

          <AppInput
            id="phone"
            v-model="phone"
            label="Номер телефона"
            required
            :error="v$.phone?.$errors[0]?.$message"
          />
        </fieldset>

        <AppInput
          id="company"
          v-model="company"
          label="Название компании"
          required
          :error="v$.company?.$errors[0]?.$message"
        />

        <AppTextarea
          id="message"
          v-model="message"
          label="Комментарий"
          not-required
        />

        <div class="form__actions">
          <button type="submit" class="button">Отправить</button>
          <p>
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих
            персональных данных.
          </p>
        </div>
      </form>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/Ui/AppModal.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import AppTextarea from "@/components/Ui/AppTextarea.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
  name: "RequestModal",

  components: { AppTextarea, AppInput, AppModal },

  emits: ["close-modal"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      name: "",
      phone: "",
      company: "",
      message: "",
    };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("Заполните поле", required),
      },
      phone: {
        required: helpers.withMessage("Заполните поле", required),
      },
      company: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };
  },

  methods: {
    sendRequest() {
      this.v$.$validate();
    },
  },
};
</script>

<style scoped lang="scss">
.request-modal {
  max-width: 776px;

  > h3 {
    @include h3();
    color: $text-primary;
    width: calc(100% - 32px);
  }

  > p {
    margin: ($base-space * 2) 0 0;
    color: $text-third;
    @include s-text-regular();
  }
}

.form {
  margin: ($base-space * 10) 0 0;

  @media (max-width: 1023px) {
    margin: ($base-space * 6) 0 0;
  }

  > fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $base-space * 4;
    padding: 0;
    border: none;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-gap: $base-space * 3;
    }
  }

  > .app-input {
    margin: ($base-space * 4) 0 0;

    @media (max-width: 767px) {
      max-width: ($base-space * 3) 0 0;
    }
  }

  > .app-textarea {
    margin: ($base-space * 4) 0 0;

    @media (max-width: 767px) {
      max-width: ($base-space * 3) 0 0;
    }
  }

  &__actions {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ($base-space * 4) ($base-space * 8);
    margin: ($base-space * 10) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    @media (max-width: 767px) {
      flex-flow: column;

      > button {
        width: 100%;
      }
    }

    > p {
      @include caption-regular();
      color: $text-fifth;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}
</style>
