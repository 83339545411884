<template>
  <div class="it-park" v-if="show_banner">
    <div class="container">
      <a href="https://itpark.spb.ru" target="_blank">
        Разработано в
        <svg
          width="84"
          height="22"
          viewBox="0 0 84 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1002_9855)">
            <path
              d="M0 17.9487V16.5685H3.71622V9.0678H0V7.68768H5.41686V16.5685H9.07011V17.9487H0ZM3.27532 4.80739C3.27532 4.44735 3.40129 4.14732 3.65325 3.84729C3.90518 3.60726 4.22011 3.42725 4.66103 3.42725C5.10193 3.42725 5.41686 3.54726 5.73179 3.84729C5.98375 4.08731 6.17271 4.44735 6.17271 4.80739C6.17271 5.16742 6.04675 5.46745 5.73179 5.70747C5.47986 5.9475 5.10193 6.06751 4.66103 6.06751C4.28311 6.06751 3.90518 5.9475 3.65325 5.70747C3.40129 5.46745 3.27532 5.16742 3.27532 4.80739Z"
              fill="#FCFCFC"
            />
            <path
              d="M8.88126 7.68777H11.4637V5.64758L13.1644 5.22754V7.68777H18.8332V9.06792H13.1014V13.8684C13.1014 14.8885 13.3533 15.6086 13.8572 16.0886C14.3611 16.5687 15.117 16.8087 15.9988 16.8087C16.6287 16.8087 17.1955 16.6887 17.6994 16.4487C18.2033 16.2086 18.5813 15.9686 19.0222 15.6686L19.589 16.8687C19.0851 17.2287 18.5183 17.5288 17.8254 17.7688C17.1325 18.0088 16.4397 18.1288 15.6839 18.1288C15.117 18.1288 14.5501 18.0688 14.0462 17.8888C13.5423 17.7088 13.1014 17.4687 12.7235 17.1687C12.3455 16.8687 12.0306 16.4487 11.7787 15.9086C11.5267 15.3685 11.4637 14.7685 11.4637 14.1084V9.06792H8.88126V7.68777Z"
              fill="#FCFCFC"
            />
            <path
              d="M26.9586 3.42725H27.5884L32.8162 11.8281H31.0527L27.2105 5.52745L23.1794 11.7681H21.4157L26.9586 3.42725Z"
              fill="#F15735"
            />
            <path
              d="M36.4691 11.2877C36.4691 11.1677 36.4691 10.9877 36.4691 10.8076C36.4691 10.6276 36.4691 10.3876 36.4065 10.2076C36.3435 10.0276 36.3435 9.78755 36.3435 9.60754C36.3435 9.42752 36.2805 9.24748 36.2805 9.06746H34.8315V7.74734H37.6661L37.8551 9.18747H37.9811C38.3588 8.70743 38.8628 8.28739 39.4298 7.98737C39.9964 7.68734 40.7524 7.50732 41.6341 7.50732C43.146 7.50732 44.3427 7.92736 45.0984 8.70743C45.8543 9.48753 46.2323 10.8076 46.2323 12.6678C46.2323 13.5079 46.1063 14.288 45.8543 15.0081C45.6023 15.6681 45.2243 16.2682 44.7207 16.7482C44.2167 17.2283 43.6497 17.5883 42.957 17.8283C42.2641 18.0684 41.5084 18.1884 40.6894 18.1884C40.3744 18.1884 40.1224 18.1884 39.8707 18.1284C39.6188 18.1284 39.4298 18.0684 39.2408 18.0684C39.0518 18.0084 38.8628 18.0084 38.6738 17.9484C38.4848 17.8884 38.2958 17.8283 38.0441 17.7683V22.0887H36.4691V11.2877ZM41.3824 9.00745C40.9414 9.00745 40.5634 9.06746 40.1854 9.24748C39.8077 9.36749 39.4928 9.60754 39.1778 9.84756C38.8628 10.0876 38.6738 10.3876 38.4848 10.6876C38.2958 10.9877 38.1698 11.2877 38.1698 11.5877V16.2082C38.4848 16.3882 38.8628 16.5682 39.2408 16.6882C39.6188 16.8082 40.1224 16.8682 40.8154 16.8682C41.9491 16.8682 42.894 16.5082 43.5867 15.7881C44.2797 15.0681 44.5947 14.048 44.5947 12.6678C44.5947 11.5277 44.3427 10.6276 43.9017 9.96757C43.3347 9.30748 42.516 9.00745 41.3824 9.00745Z"
              fill="#FCFCFC"
            />
            <path
              d="M49.1296 8.52771C49.7596 8.16768 50.4523 7.92766 51.2083 7.80765C51.9639 7.68764 52.7199 7.56763 53.4756 7.56763C54.2316 7.56763 54.7985 7.68764 55.2392 7.86765C55.6802 8.10767 56.0582 8.34769 56.3102 8.70773C56.5622 9.06776 56.7512 9.42779 56.8769 9.90786C56.9399 10.3279 57.0028 10.8079 57.0028 11.228C57.0028 11.768 57.0029 12.3081 56.9399 12.9082C56.9399 13.5082 56.8769 14.1083 56.8769 14.6483C56.8769 15.3084 56.9398 15.9685 57.0028 16.5685H58.4518V17.8886H55.6172L55.4282 16.3285H55.3652C55.3022 16.4485 55.1762 16.6285 54.9875 16.8085C54.7985 16.9885 54.6095 17.1686 54.2946 17.3486C54.0426 17.5286 53.6646 17.7086 53.2869 17.8286C52.9089 17.9486 52.4049 18.0087 51.8379 18.0087C50.7673 18.0087 49.9486 17.7686 49.3186 17.2286C48.6886 16.6885 48.3736 16.0285 48.3736 15.1283C48.3736 14.4083 48.5626 13.8682 48.8776 13.3882C49.1926 12.9082 49.6336 12.5481 50.2633 12.3081C50.8933 12.068 51.5863 11.948 52.4679 11.888C53.3499 11.828 54.2945 11.888 55.3652 12.068C55.4282 11.468 55.4282 10.928 55.4282 10.5079C55.3652 10.0879 55.2392 9.78782 55.1135 9.48779C54.9245 9.24777 54.6725 9.06776 54.3576 8.94775C54.0426 8.82774 53.6646 8.76773 53.1609 8.76773C52.5309 8.76773 51.9009 8.82774 51.3343 9.00775C50.7673 9.18777 50.2003 9.36778 49.7596 9.5478L49.1296 8.52771ZM52.3419 16.7485C52.7199 16.7485 53.0979 16.6885 53.4756 16.5685C53.7906 16.4485 54.1056 16.2685 54.3576 16.0885C54.6095 15.9084 54.7985 15.6684 54.9875 15.4884C55.1762 15.2484 55.3022 15.0683 55.3652 14.8283V13.3882C54.6095 13.2682 53.9166 13.2082 53.2869 13.2082C52.6569 13.2082 52.0899 13.2682 51.6493 13.3882C51.2083 13.5082 50.8303 13.7482 50.5783 13.9882C50.3263 14.2883 50.2003 14.5883 50.2003 15.0683C50.2003 15.4884 50.3893 15.9084 50.7043 16.2685C51.0193 16.5685 51.5863 16.7485 52.3419 16.7485Z"
              fill="#FCFCFC"
            />
            <path
              d="M60.4675 17.9017V16.5216H63.3647V9.02081H60.4675V7.64066H64.4987L64.8137 8.9608H64.9394C65.4434 8.54077 65.9474 8.1807 66.577 7.88068C67.207 7.58065 67.9 7.40063 68.7187 7.40063C69.2227 7.40063 69.6007 7.46064 69.9156 7.64066C70.2303 7.82067 70.4193 8.06069 70.6083 8.42076C70.7973 8.78079 70.8603 9.20082 70.9233 9.68086C70.9863 10.1609 70.9863 10.701 70.9863 11.301H69.4747C69.4747 10.4609 69.4117 9.80087 69.2227 9.38084C69.0337 8.9608 68.7187 8.78079 68.215 8.78079C67.774 8.78079 67.396 8.84079 67.018 8.9608C66.64 9.08081 66.3254 9.26083 66.0734 9.38084C65.8214 9.50085 65.5694 9.74087 65.3804 9.92089C65.1914 10.1009 65.0654 10.2209 65.0024 10.3409V16.3415H69.0967V17.7217H60.4675V17.9017Z"
              fill="#FCFCFC"
            />
            <path
              d="M72.183 3.60742H75.6472V12.0683H76.8439L81.0642 7.68781H83.0795L78.3555 12.5483L82.2608 16.5687H83.6465V17.9488H81.4418L76.8439 13.3284H75.6472V17.9488H74.0096V4.98756H72.183V3.60742Z"
              fill="#FCFCFC"
            />
            <path
              d="M19.841 14.7085C19.778 14.8285 19.652 15.0085 19.5261 15.1285C19.2741 15.4886 18.9592 15.7886 18.5812 16.0286V17.5888C19.2111 17.2287 19.778 16.8087 20.2819 16.3286C20.7858 15.7886 21.1007 15.2485 21.3527 14.7085H19.841Z"
              fill="#FCFCFC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1002_9855">
              <rect width="84" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>

      <button @click="hideBanner">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.38772 11.28L14.1077 16L9.38772 20.7867C9.13939 21.0365 9 21.3744 9 21.7267C9 22.0789 9.13939 22.4169 9.38772 22.6667C9.51231 22.7903 9.66006 22.888 9.82251 22.9544C9.98496 23.0207 10.1589 23.0544 10.3344 23.0533C10.5099 23.0544 10.6838 23.0207 10.8463 22.9544C11.0087 22.888 11.1565 22.7903 11.2811 22.6667L16.2483 17.6409L21.2155 22.6667C21.3401 22.7903 21.4878 22.888 21.6503 22.9544C21.8127 23.0207 21.9867 23.0544 22.1622 23.0533C22.3376 23.0544 22.5116 23.0207 22.674 22.9544C22.8365 22.888 22.9843 22.7903 23.1088 22.6667C23.3572 22.4169 23.4966 22.0789 23.4966 21.7267C23.4966 21.3744 23.3572 21.0365 23.1088 20.7867L18.3888 16L23.1088 11.28C23.3572 11.0302 23.4966 10.6923 23.4966 10.34C23.4966 9.98777 23.3572 9.64983 23.1088 9.40001C22.9849 9.27504 22.8374 9.17585 22.6749 9.10816C22.5125 9.04047 22.3382 9.00562 22.1622 9.00562C21.9862 9.00562 21.8119 9.04047 21.6494 9.10816C21.4869 9.17585 21.3395 9.27504 21.2155 9.40001L16.2483 14.3672L11.2811 9.40001C11.1571 9.27504 11.0096 9.17585 10.8472 9.10816C10.6847 9.04047 10.5104 9.00562 10.3344 9.00562C10.1584 9.00562 9.9841 9.04047 9.82162 9.10816C9.65914 9.17585 9.51167 9.27504 9.38772 9.40001C9.13939 9.64983 9 9.98777 9 10.34C9 10.6923 9.13939 11.0302 9.38772 11.28Z"
            fill="#F1F2F7"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItPark",

  data() {
    return {
      show_banner: true,
    };
  },

  methods: {
    hideBanner() {
      this.show_banner = !this.show_banner;
    },
  },
};
</script>

<style scoped lang="scss">
.it-park {
  background: #082fec;
  padding: 14px 0;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      color: #fcfcfc;
      text-decoration: none;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}
</style>
