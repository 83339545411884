import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
    beforeEnter: (to, from, next) => {
      next({ name: "HomeView" });
    },
  },

  {
    path: "/",
    name: "HomeView",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
