<template>
  <div class="modal">
    <div class="modal__wrapper">
      <button @click="$emit('close')">
        <i class="icon-close" />
      </button>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModal",

  emits: ["close"],
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  background: $background-overlay;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    background: $background-primary;
    padding: ($base-space * 15);
    border-radius: 24px;
    position: relative;
    max-height: 90vh;
    overflow: auto;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1023px) {
      padding: ($base-space * 12);
      width: calc(100vw - 64px);
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6);
      width: calc(100vw - 48px);
    }

    @media (max-width: 424px) {
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }

    > button {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-sixth;
      }
    }
  }
}
</style>
