<template>
  <div class="app-textarea">
    <label v-if="label" :for="id">
      {{ label }} <span v-if="required" class="required">*</span>
      <span v-if="notRequired" class="not-required">- необязательно</span>
    </label>

    <div class="textarea" :class="{ textarea_error: error }">
      <textarea
        :id="id"
        :value="modelValue"
        @input="updateTextareaValue"
        :disabled="disabled"
      />
    </div>

    <span class="error" v-if="error && !hideError">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AppTextarea",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },
  },

  methods: {
    updateTextareaValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.app-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;

  > label {
    color: $text-primary;
    @include caption-regular();
  }

  > .textarea {
    width: 100%;

    > textarea {
      width: 100%;
      height: 102px;
      padding: ($base-space * 4) ($base-space * 5);
      resize: none;
      @include s-text-regular();
      color: $text-primary;
      background: none;
      outline: none;
      border: 1px solid $stroke-secondary;
      border-radius: 8px;
      padding: ($base-space * 4);

      &::placeholder {
        color: $text-sixth;
      }

      &:hover {
        border-color: $stroke-primary;
      }

      &:focus {
        outline: none;
        border-color: $accent;
      }
    }

    &_error {
      > textarea {
        border-color: $text-accent-second;
      }
    }
  }
}
</style>
