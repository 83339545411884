<template>
  <MobileMenu v-if="show_mobile_menu" @close="show_mobile_menu = false" />
  <ItPark />

  <RequestModal @close="show_request_modal = false" v-if="show_request_modal" />

  <ContactModal @close="show_contact_modal = false" v-if="show_contact_modal" />

  <header
    class="header"
    :class="{
      header_unpinned: scrolled,
      header_fixed: lastPosition > 205,
      header_shadow: lastPosition > 205,
    }"
  >
    <div class="container">
      <button class="burger-menu" @click="show_mobile_menu = true">
        <i class="icon-burger-menu" />
      </button>

      <router-link :to="{ name: 'HomeView' }">
        <img src="./assets/images/logo.svg" alt="logotype" />
      </router-link>

      <nav>
        <a href="#offer">Преимущества</a>
        <a href="#create">Как работает</a>
        <a href="#solutions">Решения</a>
        <a href="#faq">FAQ</a>
        <a href="#partners">Партнеры</a>
        <a href="#reviews">Отзывы</a>
      </nav>

      <button
        class="button button_outline button_small"
        @click="show_request_modal = true"
      >
        Оставить заявку
      </button>
    </div>
  </header>

  <main>
    <router-view />
  </main>

  <footer class="footer">
    <div class="container">
      <img src="./assets/images/logo.svg" alt="" />

      <nav>
        <a href="#offer">Преимущества</a>
        <a href="#create">Как работает</a>
        <a href="#solutions">Решения</a>
        <a href="#faq">FAQ</a>
        <a href="#partners">Партнеры</a>
        <a href="#reviews">Отзывы</a>
      </nav>

      <button class="button button_small" @click="show_contact_modal = true">
        Обратная свзяь
      </button>

      <p>© {{ currentYear }} GetCoupon</p>
    </div>
  </footer>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MobileMenu from "@/components/MobileMenu.vue";
import RequestModal from "@/components/RequestModal.vue";
import ItPark from "@/components/ItPark.vue";
import ContactModal from "@/components/ContectModal.vue";

export default {
  name: "app",
  components: { ContactModal, ItPark, RequestModal, MobileMenu },

  data() {
    return {
      scrolled: false,
      lastPosition: 0,
      show_mobile_menu: false,
      show_request_modal: false,
      show_contact_modal: false,
    };
  },

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
    }),

    currentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    ...mapMutations({
      setPageWidth: "system/SET_PAGE_WIDTH",
    }),

    scrollHandler() {
      if (this.lastPosition < window.scrollY && 205 < window.scrollY) {
        this.scrolled = true;
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
      }

      this.lastPosition = window.scrollY;
    },
  },

  created() {
    window.addEventListener("scroll", this.scrollHandler);
    window.addEventListener("resize", this.setPageWidth);
    this.setPageWidth();
  },

  unmounted() {
    window.removeEventListener("scroll", this.scrollHandler);
    window.removeEventListener("resize", this.setPageWidth);
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: ($base-space * 6) 0;
  background: $background-primary;

  &_shadow {
    @include high-shadow();
  }

  &_unpinned {
    opacity: 0;
  }

  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  @media (max-width: 1023px) {
    padding: ($base-space * 4) 0;
  }

  > .container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: $base-space * 12;

    @media (max-width: 1023px) {
      grid-gap: $base-space * 2;
      grid-template-columns: 32px auto 1fr;
      justify-items: end;
    }

    .burger-menu {
      width: 32px;
      height: 32px;
      display: none;
      align-items: center;
      justify-content: center;

      @media (max-width: 1023px) {
        display: flex;
      }

      > i {
        width: 32px;
        height: 32px;
        background: $text-primary;
      }
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        @media (max-width: 1239px) {
          width: 76px;
        }

        @media (max-width: 1023px) {
          width: 61px;
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 8;

      @media (max-width: 1239px) {
        gap: $base-space * 4;
      }

      @media (max-width: 1023px) {
        display: none;
      }

      > a {
        color: $text-third;
        @include s-text-regular();
        transition: ease-out 0.05s;

        &:hover {
          color: $accent;
        }
      }
    }
  }
}

.footer {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &:before {
    content: "";
    display: block;
    width: calc(100% - 200px);
    height: 1px;
    background: $stroke-secondary;
    position: absolute;

    @media (max-width: 1239px) {
      width: calc(100% - 96px);
    }

    @media (max-width: 1023px) {
      width: calc(100vw - 64px);
    }

    @media (max-width: 767px) {
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }
  }

  .container {
    padding: ($base-space * 10) 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: ($base-space * 12) ($base-space * 15);

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }

    > img {
      @media (max-width: 1239px) {
        width: 76px;
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 8;

      @media (max-width: 1239px) {
        gap: $base-space * 4;
      }

      @media (max-width: 1023px) {
        flex-flow: column;
        align-items: flex-start;
      }

      > a {
        color: $text-third;
        @include s-text-regular();
        transition: ease-out 0.05s;

        &:hover {
          color: $accent;
        }
      }
    }

    > p {
      color: $text-fifth;
      @include s-text-regular();
    }
  }
}
</style>
