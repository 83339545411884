const state = {
  window_width: 0,
};

const mutations = {
  SET_PAGE_WIDTH(store) {
    store.window_width = window.innerWidth;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
