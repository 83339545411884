<template>
  <aside class="mobile-menu">
    <div class="mobile-menu__wrapper">
      <header>
        <img src="../assets/images/logo.svg" alt="logotype" />

        <button @click="$emit('close')">
          <i class="icon-close" />
        </button>
      </header>

      <nav>
        <a href="#offer">Преимущества</a>
        <a href="#create">Как работает</a>
        <a href="#solutions">Решения</a>
        <a href="#faq">FAQ</a>
        <a href="#partners">Партнеры</a>
        <a href="#reviews">Отзывы</a>
      </nav>

      <button class="button button_large">Вход / Регистрация</button>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MobileMenu",

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
    }),
  },

  watch: {
    $route() {
      this.$emit("close");
    },

    windowWidth(newValue) {
      if (newValue > 1023) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-menu {
  z-index: 50;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $background-primary;

  &__wrapper {
    padding: ($base-space * 6) ($base-space * 6) 80px ($base-space * 6);
    position: relative;
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > img {
        width: 76px;
      }

      > button {
        width: 32px;
        height: 32px;

        > i {
          width: 32px;
          height: 32px;
          background: $text-primary;
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      grid-gap: $base-space * 6;
      margin: ($base-space * 15) 0 0;

      > a {
        @include l-text-regular();
        color: $text-third;
      }
    }

    > .button {
      width: calc(100% - 48px);
      position: fixed;
      bottom: 24px;
      left: 24px;
      right: 0;
    }
  }
}
</style>
