<template>
  <AppModal @close="$emit('close-modal')">
    <div class="contact-modal">
      <h3>Обратная свзяь</h3>

      <form @submit.prevent="sendRequest" class="form">
        <AppInput
          id="company"
          v-model="name"
          label="ФИО"
          required
          :error="v$.name?.$errors[0]?.$message"
        />

        <AppInput
          id="company"
          v-model="email"
          label="E-mail"
          required
          :error="v$.email?.$errors[0]?.$message"
        />

        <AppTextarea
          id="message"
          v-model="message"
          label="Описание"
          not-required
        />

        <div class="form__actions">
          <button type="submit" class="button" :disabled="button_loader">
            Отправить
          </button>
          <p>
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих
            персональных данных.
          </p>
        </div>
      </form>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/Ui/AppModal.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import AppTextarea from "@/components/Ui/AppTextarea.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";

export default {
  name: "ContactModal",

  components: { AppTextarea, AppInput, AppModal },

  emits: ["close-modal"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      name: "",
      email: "",
      message: "",
      button_loader: false,
    };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("Заполните поле", required),
      },
      email: {
        required: helpers.withMessage("Заполните поле", required),
        email: helpers.withMessage("Неверный формат", email),
      },
    };
  },

  methods: {
    sendRequest() {
      this.v$.$validate();

      if (!this.button_loader && !this.v$.$error) {
        this.button_loader = true;

        setTimeout(() => {
          this.$emit("close");
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact-modal {
  max-width: 776px;

  > h3 {
    @include h3();
    color: $text-primary;
    width: calc(100% - 32px);
  }

  > p {
    margin: ($base-space * 2) 0 0;
    color: $text-third;
    @include s-text-regular();
  }
}

.form {
  margin: ($base-space * 10) 0 0;

  @media (max-width: 1023px) {
    margin: ($base-space * 6) 0 0;
  }

  > fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $base-space * 4;
    padding: 0;
    border: none;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-gap: $base-space * 3;
    }
  }

  > .app-input {
    margin: ($base-space * 4) 0 0;

    @media (max-width: 767px) {
      max-width: ($base-space * 3) 0 0;
    }
  }

  > .app-textarea {
    margin: ($base-space * 4) 0 0;

    @media (max-width: 767px) {
      max-width: ($base-space * 3) 0 0;
    }
  }

  &__actions {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ($base-space * 4) ($base-space * 8);
    margin: ($base-space * 10) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    @media (max-width: 767px) {
      flex-flow: column;

      > button {
        width: 100%;
      }
    }

    > p {
      @include caption-regular();
      color: $text-fifth;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}
</style>
